import axiosUploader from "./axios-uploader";
import { FileUploadedInput, FileUploadedResults, MediaFile, MediaFileInput, UploadResults } from "@graphql/graphql";
import { toastsStore } from "@store";
import { t } from "i18next";
import { determineFileType } from "./determine-file-type";
import { compressImage } from "./compress-image";
import { apolloClient } from "src/apollo-client";
import { fileUploaded, upload } from "@graphql/docs/queries";

const uploadFileAxios = (
  url: string,
  file: File,
  onUploadProgress?: (percentCompleted: number) => void,
) =>
  axiosUploader.put(url, file, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': file.type,
      // 'Content-Type':
      //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress && progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 90) / progressEvent.total);
        onUploadProgress(percentCompleted);
      }
    },
  })
  .catch(() => null)

export const uploadFile = async (
  fileName: string,
  file: File,
  onUploadProgress?: (percentCompleted: number) => void,
): Promise<MediaFileInput | null> => {
  try {
    const fileType = determineFileType(file);

    if (fileType === 'unknown') {
      toastsStore.addErrorToast(t('toasts.error.loadType'));
      return null;
    }

    let formData = new FormData();

    if (fileType === 'image') {
      if (file.size >= 3000000) {
        const compressedFile = await compressImage(file);
        formData.append('file', compressedFile);
      } else {
        formData.append('file', file);
      }
    
      formData.append('fileName', fileName);
    } else if (fileType === 'video') {
      formData.append('file', file);
      formData.append('fileName', fileName);
    }

    // const mediaFile = await uploadFileAxios(formData, fileType, onUploadProgress);
    const input = { fileName, mimetype: file.type };
    const link = await apolloClient.query<{upload: UploadResults}>({
      query: upload,
      variables: { input },
    })
    if (!link.data?.upload || !link.data?.upload?.url) {
      toastsStore.addErrorToast(t('toasts.error.loadMedia'));
      return null;
    }
    const response = await uploadFileAxios(link.data.upload.url, file, onUploadProgress);
    console.log('mediaFile:', response);

    if (response?.status !== 200) {
      toastsStore.addErrorToast(t('toasts.error.loadMedia'));
    }

    if (onUploadProgress) {
      onUploadProgress(100);
    }

    if (fileType === 'video') {
      const fileUploadedInput: FileUploadedInput = {
        key: link?.data?.upload?.mediaFile?.key ?? '',
        type: file.type,
      }
      await apolloClient.query<{fileUploaded: FileUploadedResults}>({
        query: fileUploaded,
        variables: { input: fileUploadedInput },
      })
    }

    if (
      !link.data.upload.mediaFile?.type ||
      !link.data.upload.mediaFile?.key ||
      !link.data.upload.mediaFile?.url
    ) {
      return null;
    }

    return {
      key: link.data.upload.mediaFile?.key,
      type: link.data.upload.mediaFile?.type,
      url: link.data.upload.mediaFile?.url,
      converted: fileType === 'image' ? true : false,
    };
  } catch (error) {
    console.error("Ошибка при загрузке файла:", error);
    toastsStore.addErrorToast(t('toasts.error.loadMedia'));
    return null;
  }
};
